import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/web.dom.iterable";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Head from '@/components/head/index';
import { getcustomerlevel, getStructureListArr } from '@/api/PublicAPI';
import { MyLiberumreceive, MyLiberumdistribution } from '@/api/ceshi_xiugai/Sendcustomerstohighseas';
import { MyCirculationList, agentFlowerNameList, Majorlist, getLabelpeer as _getLabelpeer, FollwupIndex } from '@/api/ceshi_xiugai/whole';
export default {
  components: {
    Head: Head
  },
  data: function data() {
    return {
      //系统教程
      tab: 0,
      course: this.$route.query.course,
      dialogFormVisible: false,
      comNameshow: false,
      comWebsiteshow: false,
      //系统教程
      flower_id: '',
      //学员来源id
      flower_Array: [],
      //学员来源
      circulation_type: [],
      //流转类型
      circulation_id: '',
      //流转类型id
      get_type: 0,
      title: '已流转客户',
      value1: '',
      query: {
        page: 1,
        pagesize: 10,
        total: 0 //总条数，不是总页数
      },
      customer_id: '',
      customer: [],
      //学员级别
      mobile_cname: '',
      //	微信/手机号/学员称呼
      tableData: [],
      Alltableoptions: [],
      staffSelectVisible: false,
      staffResult: [],
      staffDisableArr: [],
      condition: [],
      radio: false,
      tempId: '',
      editForm: {
        type: '',
        customer_levels: '',
        //学员级别（传id）
        source_client_id: '',
        //花名（学员来源） 传id
        create_stime: '',
        //创建开始时间
        create_etime: '',
        //创建结束时间
        ittnzy: '',
        //意向专业（1,2,3）
        exchange_stime: '',
        //流转日期开始时间
        exchange_etime: '',
        //流转日期结束时间
        start_lib_time: '',
        end_lib_time: '',
        exchange_realname: '',
        //数据接收人人
        structure_ids: '',
        //原始部门 （1,2,3）
        create_time_order: '',
        //创建日期排序 1正序 2倒序 0不排序
        exchange_time_order: '',
        //流转日期日期排序 1正序 2倒序 0不
        mobile_cname: '',
        //手机号 微信 qq号 用户名
        communication_name: '',
        communication_website: ''
      },
      finalFollowshow: false,
      nexttimeshow: false,
      //下次回访日期
      lasttimeshow: false,
      //最后跟进日期
      createtimeshow: false,
      //创建日期
      exchangeshow: false,
      //
      circulationshow: false,
      ittnzyshow: false,
      //意向专业
      createnameshow: false,
      //创建人
      remarksshow: false,
      //备注
      labelpeersshow: false,
      //客户标签
      structure_idshow: false,
      //组织部门
      createtime: '',
      //创建日期
      exchange_realname: '',
      //数据接收人
      circulation_time: '',
      //流转日期
      showittnzy: '',
      //意向专业
      Intendeds: [],
      //意向专业
      create_realname: '',
      //创建人
      structure_id: '',
      //组织部门
      bumenoptions: [],
      labelpeers: '',
      //客户标签
      labelpeersEs: [],
      remarks: '',
      //备注
      followUp: false,
      //跟进弹窗
      activities: [],
      reverse: true
    };
  },
  mounted: function mounted() {
    var _this = this;
    var jurisdiction = [];
    var tion = this.$store.getters.StatisTics;
    if (tion != '') {
      tion.forEach(function (item) {
        jurisdiction.push(item.title);
      });
    }
    if (jurisdiction.indexOf('编辑教程') > -1) {
      this.tutorialEs = true;
    } else {
      this.tutorialEs = false;
    }
    if (this.course != 1) {
      this.Getcustomer();
      this.majorDedMaj(); //意向专业
      this.getStructure(); //组织部门
      this.getLabelpeer();
    } else {
      SystemManualInfo({
        role_id: this.menuId
      }).then(function (res) {
        _this.centerHtml = res.data.contents;
      });
    }
  },
  created: function created() {
    this.GetList();
    this.flowerArray();
  },
  methods: {
    detailsClick: function detailsClick() {
      var _this2 = this;
      //系统教程
      SystemManualInfo({
        role_id: this.menuId
      }).then(function (res) {
        _this2.content = res.data.contents;
        _this2.dialogFormVisible = true;
      });
    },
    updateData: function updateData() {
      var _this3 = this;
      //系统教程
      this.prohibit = true;
      SystemManualEdit({
        role_id: this.menuId,
        contents: this.content
      }).then(function (res) {
        _this3.prohibit = false;
        _this3.dialogFormVisible = false;
        _this3.$notify({
          title: '成功',
          message: '编辑成功',
          type: 'success'
        });
        SystemManualInfo({
          role_id: _this3.menuId
        }).then(function (res) {
          _this3.centerHtml = res.data.contents;
        });
      });
    },
    //学员级别list
    Getcustomer: function Getcustomer() {
      var _this4 = this;
      getcustomerlevel().then(function (res) {
        _this4.customer = res.data;
      });
    },
    flowerArray: function flowerArray() {
      var _this5 = this;
      //花名
      agentFlowerNameList().then(function (res) {
        _this5.flower_Array = res.data;
      });
    },
    getStructure: function getStructure() {
      var _this6 = this;
      //组织部门
      getStructureListArr().then(function (respomse) {
        _this6.bumenoptions = respomse.data;
      });
    },
    majorDedMaj: function majorDedMaj() {
      var _this7 = this;
      //意向专业默认
      Majorlist().then(function (res) {
        _this7.Intendeds = res.data.major_list;
      });
    },
    // 客户标签
    getLabelpeer: function getLabelpeer() {
      var _this8 = this;
      _getLabelpeer().then(function (res) {
        //客户标签
        res.data.map(function (itemEs) {
          itemEs.id = String(itemEs.id);
        });
        _this8.labelpeersEs = res.data;
        // this.customer = res.data;
      });
    },
    GetList: function GetList() {
      var _this9 = this;
      this.editForm.pagesize = this.query.pagesize;
      this.editForm.page = this.query.page;
      MyCirculationList(this.editForm).then(function (res) {
        _this9.tableData = res.data.data;
        _this9.query.total = res.data.total;
        _this9.circulation_type = res.data.exchange_types;
      });
    },
    //学员级别查询
    customerhand: function customerhand(val) {
      var share = val.map(function (e) {
        return e[e.length - 1];
      }).toString(); // 学员级别的最后一位ID
      this.editForm.customer_levels = share;
      this.GetList();
    },
    //学员来源查询
    flowerArrayEs: function flowerArrayEs() {
      var _this10 = this;
      if (this.flower_id !== '') {
        this.editForm.source_client_id = this.flower_id;
        this.$nextTick(function () {
          _this10.GetList();
        });
      } else {
        this.editForm.source_client_id = '';
        this.$nextTick(function () {
          _this10.GetList();
        });
      }
    },
    //流转类型查询
    circulationArrayEs: function circulationArrayEs() {
      var _this11 = this;
      if (this.circulation_id !== '') {
        this.editForm.type = this.circulation_id;
        this.$nextTick(function () {
          _this11.GetList();
        });
      } else {
        this.editForm.type = '';
        this.$nextTick(function () {
          _this11.GetList();
        });
      }
    },
    //查询
    handleFilter: function handleFilter() {
      var _this12 = this;
      if (this.mobile_cname !== '') {
        this.editForm.mobile_cname = this.mobile_cname;
        this.$nextTick(function () {
          _this12.GetList();
        });
      } else {
        this.editForm.mobile_cname = '';
        this.$nextTick(function () {
          _this12.GetList();
        });
      }
    },
    //每页最多显示条数10   20   30   50
    handleSizeChange: function handleSizeChange(val) {
      var _this13 = this;
      this.query.pagesize = val;
      this.$nextTick(function () {
        _this13.GetList();
      });
    },
    //翻页
    handleCurrentChange: function handleCurrentChange(val) {
      var _this14 = this;
      this.query.page = val;
      this.$nextTick(function () {
        _this14.GetList();
      });
    },
    selsChange: function selsChange(val) {
      this.Alltableoptions = val;
      this.tempId = this.Alltableoptions.map(function (item) {
        return item.customer_data_id;
      }).join(',');
    },
    staffSelectConfirm: function staffSelectConfirm(resultNameArr, result) {
      var _this15 = this;
      if (this.Alltableoptions.length !== 0) {
        this.$confirm('确认分配该记录吗?', '提示', {
          type: 'warning'
        }).then(function () {
          var para = {
            customer_data_ids: String(_this15.tempId),
            user_id: result.toString()
          };
          MyLiberumdistribution(para).then(function (res) {
            _this15.$notify({
              title: '提示',
              message: res.data.toString(),
              type: 'success',
              duration: 3000
            });
            _this15.$nextTick(function () {
              //获取列表方法
              _this15.GetList();
            });
          });
        }).catch(function () {});
      } else {
        this.$message('请选中分配项');
      }
    },
    //表头显示搜索框
    Activeshow: function Activeshow(item) {
      if (item === '创建日期') {
        this.createtimeshow = true;
      } else if (item === '数据接收人') {
        this.exchangeshow = true;
      } else if (item === '流转日期') {
        this.circulationshow = true;
      } else if (item === '推广项目') {
        this.comNameshow = true;
      } else if (item === '推广地址') {
        this.comWebsiteshow = true;
      }
    },
    //点×
    Deletesshow: function Deletesshow(item) {
      var _this16 = this;
      if (item === '创建日期') {
        this.createtimeshow = false;
        this.editForm.create_stime = '';
        this.editForm.create_etime = '';
        this.createtime = '';
        this.GetList(this.editForm.page, this.editForm.pagesize);
      } else if (item === '数据接收人') {
        this.exchangeshow = false;
        this.exchange_realname = '';
        this.editForm.exchange_realname = '';
        this.GetList(this.editForm.page, this.editForm.pagesize);
      } else if (item === '流转日期') {
        this.circulationshow = false;
        this.editForm.exchange_stime = '';
        this.editForm.exchange_etime = '';
        this.circulation_time = '';
        this.$nextTick(function () {
          _this16.GetList(_this16.editForm.page, _this16.editForm.pagesize);
        });
      } else if (item === '推广项目') {
        this.comNameshow = false;
        this.editForm.communication_name = '';
        this.GetList(this.editForm.page, this.editForm.pagesize);
      } else if (item === '推广地址') {
        this.comWebsiteshow = false;
        this.editForm.communication_website = '';
        this.GetList(this.editForm.page, this.editForm.pagesize);
      }
    },
    //数据接收人
    exchangename: function exchangename() {
      this.editForm.exchange_realname = this.exchange_realname;
      this.GetList(this.editForm.page, this.editForm.pagesize);
    },
    comNameClick: function comNameClick() {
      this.GetList(this.editForm.page, this.editForm.pagesize);
    },
    comWebsiteClick: function comWebsiteClick() {
      this.GetList(this.editForm.page, this.editForm.pagesize);
    },
    //创建时间
    creatDateTime: function creatDateTime() {
      if (this.createtime == null) {
        this.editForm.create_stime = '';
        this.editForm.create_etime = '';
        this.GetList(this.editForm.page, this.editForm.pagesize);
      } else {
        this.editForm.create_stime = this.createtime[0].toString().substring(0, 10);
        this.editForm.create_etime = this.createtime[1].toString().substring(0, 10);
        this.GetList(this.editForm.page, this.editForm.pagesize);
      }
    },
    //流转日期筛选
    circulationEs: function circulationEs() {
      if (this.circulation_time == null) {
        this.editForm.exchange_stime = '';
        this.editForm.exchange_etime = '';
        this.GetList(this.editForm.page, this.editForm.pagesize);
      } else {
        this.editForm.exchange_stime = this.circulation_time[0].toString().substring(0, 10);
        this.editForm.exchange_etime = this.circulation_time[1].toString().substring(0, 10);
        this.GetList(this.editForm.page, this.editForm.pagesize);
      }
    },
    sortEs: function sortEs(column, prop, order) {
      // ascending 升序 、 descending降序 、 null 不排序
      if (column.prop == 'create_time') {
        //创建日期
        if (column.order == 'ascending') {
          this.editForm.create_time_order = '1';
        } else if (column.order == 'descending') {
          this.editForm.create_time_order = '2';
        } else {
          this.editForm.create_time_order = '0';
        }
        this.editForm.exchange_time_order = '0';
      } else if (column.prop == 'exchange_time') {
        //流转日期
        if (column.order == 'ascending') {
          this.editForm.exchange_time_order = '1';
        } else if (column.order == 'descending') {
          this.editForm.exchange_time_order = '2';
        } else {
          this.editForm.exchange_time_order = '0';
        }
        this.editForm.create_time_order = '0';
      }
      this.GetList(this.editForm.page, this.editForm.pagesize);
    },
    followUpCustomers: function followUpCustomers(id) {
      var _this17 = this;
      //跟进弹窗
      this.followUp = true;
      this.activities = '';
      FollwupIndex({
        id: id.customer_id,
        customer_data_id: id.customer_data_id,
        is_realname: 2
      }).then(function (res) {
        _this17.activities = res.data;
      });
    }
  }
};